import React from 'react';
import { useTranslation } from 'react-i18next';

import { H4 } from '~/components';

import { View, Wrapper } from './styles';

const ActionPlanConcluded = ({ ...rest }) => {
  const { t } = useTranslation('components');

  return (
    <View {...rest}>
      <Wrapper>

        <H4 isBold>{t('actionPlanConclued.status')}</H4>
      </Wrapper>
    </View>
  );
};

export default ActionPlanConcluded;
